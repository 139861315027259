const defineSocialsAvailability = (list, disabledKeysList) => {
    if (!(disabledKeysList === null || disabledKeysList === void 0 ? void 0 : disabledKeysList.length))
        return { availableKeys: list, disabledKeys: {} };
    const availableKeys = Object.assign({}, list);
    const disabledKeys = {};
    disabledKeysList.forEach((disabledKey) => {
        if (availableKeys[disabledKey]) {
            delete availableKeys[disabledKey];
            disabledKeys[disabledKey] = list[disabledKey];
        }
    });
    return { availableKeys, disabledKeys };
};
export default defineSocialsAvailability;
