import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FormControl, MenuItem, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import SocialsUtil from '@utils/socials';
import IconText from '@shared/ui/icons/IconText';
import Select from '@shared/ui/inputs/Select';
import defineSocialsAvailability from './utils';
const Container = styled(FormControl)(() => ({
    display: 'flex',
    width: '100%',
}));
const IconTextStyled = styled(IconText)(props => ({
    color: `${props.theme.palette.text.primary}`,
    gap: 10,
}));
const Divider = styled.div(() => ({
    width: '100%',
    borderTop: '1px solid #EFF1F3',
}));
const SocialsSelect = ({ className = undefined, value = '', onChange = () => { }, disabledKeys: disabledKeysList = [], disabled = undefined, }) => {
    const { t } = useTranslation();
    const allSocials = useMemo(() => SocialsUtil.getSocials(t), [t]);
    const { availableKeys, disabledKeys } = useMemo(() => {
        const { availableKeys: availableKeysRes, disabledKeys: disabledKeysRes, } = defineSocialsAvailability(allSocials, disabledKeysList);
        return {
            availableKeys: availableKeysRes,
            disabledKeys: disabledKeysRes,
        };
    }, [allSocials, disabledKeysList]);
    const socialIcon = (type) => {
        if (allSocials[type]) {
            return allSocials[type].mainIcon;
        }
        return () => undefined;
    };
    const socialName = (type) => { var _a; return ((_a = allSocials[type]) === null || _a === void 0 ? void 0 : _a.name); };
    const onSelectChange = (event) => {
        // NOTE: target.value should be SocialNetworkType,
        // but for some reason it is still a string in the end
        onChange(event.target.value);
    };
    const renderValue = (selected) => {
        if (!selected) {
            return t('profile.add_social');
        }
        return (_jsx(IconTextStyled, { IconComponent: socialIcon(selected)(), text: socialName(selected) }));
    };
    return (_jsx(Container, { className: className, children: _jsxs(Select, { size: 'small', value: value, onChange: onSelectChange, displayEmpty: true, renderValue: renderValue, disabled: disabled, children: [Object.keys(availableKeys).map((socialsKey) => (_jsx(MenuItem, { value: socialsKey, children: _jsx(IconTextStyled, { IconComponent: socialIcon(socialsKey)(), text: socialName(socialsKey) }) }, socialsKey))), (disabledKeysList === null || disabledKeysList === void 0 ? void 0 : disabledKeysList.length) > 0 ? _jsx(Divider, {}) : null, Object.keys(disabledKeys).map((socialsKey) => (_jsx(MenuItem, { value: socialsKey, disabled: true, children: _jsx(IconTextStyled, { IconComponent: socialIcon(socialsKey)(), text: socialName(socialsKey) }) }, socialsKey)))] }) }));
};
export default SocialsSelect;
