const defineDisabledKeys = (values) => {
    var _a;
    return ((_a = Object.values(values)) === null || _a === void 0 ? void 0 : _a.reduce((acc, value) => {
        if (value === null || value === void 0 ? void 0 : value.type) {
            acc.push(value.type);
        }
        return acc;
    }, [])) || [];
};
export default defineDisabledKeys;
