var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAppSelector } from '@app/store/Hooks';
import { useFollowProfileMutation, useUnfollowProfileMutation } from '@shared/api/profile';
const useProfileActions = () => {
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const [follow, { isLoading: isFollowProfileInProgress },] = useFollowProfileMutation();
    const [unfollow, { isLoading: isUnfollowProfileInProgress },] = useUnfollowProfileMutation();
    const followProfile = (profileId) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield follow({ id: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id, idToFollow: profileId });
        if (res === null || res === void 0 ? void 0 : res.error) {
            return {
                error: res === null || res === void 0 ? void 0 : res.error,
            };
        }
        return true;
    });
    const unfollowProfile = (profileId) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield unfollow({
            id: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id,
            idToUnfollow: profileId,
        });
        if (res === null || res === void 0 ? void 0 : res.error) {
            return {
                error: res === null || res === void 0 ? void 0 : res.error,
            };
        }
        return true;
    });
    return {
        followProfile,
        isFollowProfileInProgress,
        unfollowProfile,
        isUnfollowProfileInProgress,
    };
};
export default useProfileActions;
